<template>
	<v-container fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="11">
        <base-heading :title="`Contactos: ${providerName}`">
          <base-btn v-if="providerContacts.length > 0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
      <v-col cols="12" sm="1" align="center">
        <base-btn color="grey darken-3" text @click="goBack" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <base-data-table 
          v-model="sortBy"
          :headers="headers" 
          :items="providerContacts"
          :showDialog="dialog"
          showEnabledField
          @loadDataTable="toList"
          @newItem="open"
        > 
          <template v-slot:item.options="{ item }">
            <base-icon-btn @click="editItem(item)" icon="mdi-pencil" tooltip="Editar"></base-icon-btn>
            
            <template v-if="item.enabled">
              <base-icon-btn color="red darken-1" @click="enableDisableItem(item, 2)" icon="mdi-cancel" tooltip="Desactivar"></base-icon-btn>
            </template>
            <template v-else>
              <base-icon-btn color="green darken-1" @click="enableDisableItem(item, 1)" icon="mdi-check" tooltip="Activar"></base-icon-btn>
            </template>
          </template>

          <template v-slot:content>
            <base-form-stepper ref="ContactForm"
              :formTitle="formTitle"
              :step="step"
              :steps="steps"
              :formLoading="formLoading"

              @prevStepButton="step--"
              @nextStepButton="step++"
              @cancelButton="close"
              @saveButton="save"
            >
              <template v-slot:formContent-1>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.name" label="Nombres" counter :maxlength="person.nameMaxLength" 
                    :rules="[commonValRules.required, personValidationRules.nameRules.counter]" required></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.surnames" label="Apellidos" counter :maxlength="person.surnamesMaxLength"  
                    :rules="[commonValRules.required, personValidationRules.surnamesRules.counter]" required></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-number-field fullWidth v-model="editedItem.cellPhoneNumber" label="Número Celular" counter :maxlength="person.cellPhoneNumberMaxLength"
                    :rules="[personValidationRules.cellPhoneNumberRules.counter]" required></base-number-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.email" label="Correo Electrónico" counter :maxlength="person.emailMaxLength" 
                    :rules="[commonValRules.email, personValidationRules.emailRules.counter]"></base-text-field>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:formContent-2>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <base-text-field v-model="editedItem.workPosition" label="Cargo" counter maxlength="50" 
                    :rules="[commonValRules.required, validationRules.workPositionRules.counter]" required></base-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <base-number-field fullWidth v-model="editedItem.workPhone" label="Nro. Teléfono" counter maxlength="15"
                    :rules="[validationRules.workPhoneRules.counter]" ></base-number-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <base-number-field fullWidth v-model="editedItem.internalWorkNumber" label="Nro. Interno" counter maxlength="8"
                    :rules="[validationRules.internalWorkNumberRules.counter]" ></base-number-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <base-textarea outlined v-model="editedItem.comments" label="Comentarios" counter maxlength="200" 
                    :rules="[validationRules.commentsRules.counter]" ></base-textarea>
                  </v-col>
                </v-row>
              </template>
            </base-form-stepper>
          </template>
        </base-data-table>
      </v-col>
    </v-row>

    <base-pdf-viewer :show="showPdfViewer" :title="`Contactos: ${providerName}`" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
import PersonData from '@/mixins/person-data'

export default {
  name: 'ProviderContacts',
	mixins: [MainExternalData, PersonData],

  props:{
    providerId: { type: Number, required: true },
    providerName: { type: String, required: true }
  },

	data() {
		return {
      step: 1,
      steps:[
        {value: 1, text:'Datos Personales'},
        {value: 2, text:'Datos Laborales'}
      ],
			sortBy: 'fullName',
			providerContacts: [],
			headers: [
        { text: "Opciones", value: "options", sortable: false },
        { text: "Nombre", value: "fullName" },
				{ text: "Número Celular", value: "cellPhoneNumber", sortable: false},
        { text: "Cargo", value: "workPosition" },
        { text: "Correo Electrónico", value: "email", sortable: false},
        { text: "Estado", value: "enabled"}
      ],
			editedItem: {
        id: '',
        name: '',
        surnames: '',
        cellPhoneNumber: '',
        email: '',
        workPosition: '',
        workPhone: '',
        internalWorkNumber: '',
        comments: '',
      },
      defaultItem: {
        id: '',
        name: '',
        surnames: '',
        cellPhoneNumber: '',
        email: '',
        workPosition: '',
        workPhone: '',
        internalWorkNumber: '',
        comments: '',
			},
			editData: false,
      dialog: false,
      formLoading: false,
      validationRules: {
        workPositionRules: {
          counter: value => (value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
        },
        workPhoneRules: {
          counter: value => (value.length <= 15) || 'No debe tener más de 15 caracteres.',
        },
        internalWorkNumberRules: {
          counter: value => (value.length <= 8) || 'No debe tener más de 8 caracteres.',
				},
				commentsRules: {
          counter: value => (value.length <= 200) || 'No debe tener más de 200 caracteres.',
        },
      },

      //PDF report
			showPdfViewer: false,
			reportFile:{
				fileContent:'',
				fileName:''
			},
			defaultReportFile:{
				fileContent:'',
				fileName:''
			},
		}
  },
  
  computed: {
		formTitle() {
      return this.editData ? "Actualizar datos" : "Nuevo registro";
		},
  },

  watch: {
    providerId(val){
      if (val > 0) this.toList();
    }
  },

	created() {
		this.toList();
  },

	methods: {
		async toList(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.providerContacts=[];
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'providerId': me.providerId,
        };
        await me.getObjectResponse('api/ProviderContact/GetByProvider', request).then(data => {
          if (data !== undefined) {
            me.providerContacts = data.providerContacts; 
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async editItem(item) {
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'providerId': me.providerId,
          'id': item.id,
        };
        await me.getObjectResponse('api/ProviderContact/GetById', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedItem = Object.assign({}, data.providerContact);
          me.editData = true;
          me.dialog = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    open(){
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.clean();
    },
    
    clean(){
      this.step=1;
      this.editData = false;
      this.$refs.ContactForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    async save() {
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        if (me.editData) {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'providerId': me.providerId,
            'id': me.editedItem.id,

            'name': me.editedItem.name,
            'surnames': me.editedItem.surnames,
            'cellPhoneNumber': me.editedItem.cellPhoneNumber,
            'email': me.editedItem.email,

            'workPosition': me.editedItem.workPosition,
            'workPhone': me.editedItem.workPhone,
            'internalWorkNumber': me.editedItem.internalWorkNumber,
            'comments': me.editedItem.comments,
          };
          await me.getObjectResponse('api/ProviderContact/Update', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.providerContact);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        } 
        else {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'providerId': me.providerId,

            'name': me.editedItem.name,
            'surnames': me.editedItem.surnames,
            'cellPhoneNumber': me.editedItem.cellPhoneNumber,
            'email': me.editedItem.email,

            'workPosition': me.editedItem.workPosition,
            'workPhone': me.editedItem.workPhone,
            'internalWorkNumber': me.editedItem.internalWorkNumber,
            'comments': me.editedItem.comments,
          };
          await me.getObjectResponse('api/ProviderContact/Create', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.providerContact);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        }
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    addOrUpdateListItem(item){
			let me=this;
			if (!!me.providerContacts.find((x) => x.id === item.id)) {
				const index = me.providerContacts.findIndex((x) => x.id === item.id)
				me.providerContacts.splice(index, 1, item)
			} else {
				me.providerContacts.push(item);
			}
		},

    async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `el contacto <strong>${item.fullName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
		},

    async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'providerId': me.providerId,
          'id': item.id,
        };
        await me.getBaseResponse('api/ProviderContact/Enable', request).then(data => {
          if (data) {
            item.enabled = true;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'providerId': me.providerId,
          'id': item.id,
        };
        await me.getBaseResponse('api/ProviderContact/Disable', request).then(data => {
          if (data) {
            item.enabled = false;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },
    
    goBack(){
      const currentContacts = this.providerContacts.length;
      this.providerContacts = [];
      this.$emit('goBack', [this.providerId, currentContacts]);
    },

    async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'providerId': me.providerId,
				};
				await me.getObjectResponse('api/ProviderContact/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>